export default ({
  palette: { text },
  fonts,
  spacing,
  breakpoints: { down, up },
}) => ({
  newsCard: {
    color: text.primary,
    '& .MuiTouchRipple-root': {
      display: 'none',
    },
    [up('lg')]: {
      '& img': {
        transition: 'all .3s!important',
      },
      '&:hover': {
        '& img': {
          transform: 'scale(1.076)',
        },
      },
    },
    [down('xs')]: {
      '&:nth-child(n+4)': {
        display: ({ displayOnlyTop }) =>
          displayOnlyTop && 'none',
      },
    },
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
  img: {
    height: 370,
    [down('xs')]: {
      height: 270,
    },
  },
  header: {
    marginTop: spacing(2),
  },
  category: {
    display: 'flex',
    alignItems: 'center',
    fontSize: fonts.size.xxxs,
    '&::before': {
      content: '""',
      backgroundColor: 'currentColor',
      width: 4,
      height: 4,
      borderRadius: '50%',
      marginRight: spacing(1),
    },
  },
  date: {
    fontSize: fonts.size.xxxxs,
    fontWeight: 500,
    opacity: 0.7,
  },
  title: {
    maxWidth: ({ smallCard }) => smallCard && 310,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    boxOrient: 'vertical',
    lineClamp: 1,
    marginTop: spacing(2),
    textAlign: 'left',
    fontWeight: 700,
    [down('xs')]: {
      fontSize: fonts.size.xm,
    },
  },
  text: {
    opacity: 0.7,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    boxOrient: 'vertical',
    lineClamp: 2,
    marginTop: spacing(1),
    textAlign: 'left',
    fontWeight: 500,
    [down('xs')]: {
      fontSize: fonts.size.xxs,
      lineHeight: `${fonts.size.xxs + 7}px`,
    },
  },
});
