import React from 'react';
import {
  Box,
  Fade,
  makeStyles,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles(
  ({ palette: { primary, text }, spacing }) => ({
    successMessageContainer: {
      position: 'absolute',
      left: 0,
      top: -40,
      color: text.secondary,
      backgroundColor: primary.dark,
      padding: spacing(1, 1.5),
      minWidth: 110,
      '&::after': {
        content: '""',
        height: 10,
        width: 10,
        position: 'absolute',
        bottom: -5,
        left: 0,
        right: 0,
        margin: 'auto',
        backgroundColor: primary.dark,
        transform: 'rotate(45deg)',
        zIndex: 1,
      },
    },
    message: {
      fontSize: 'inherit',
      position: 'relative',
      zIndex: 2,
    },
  })
);

const SuccessMessageBox = ({ message, isVisible }) => {
  const classes = useStyles();
  return (
    <Fade
      in={isVisible}
      timeout={{ enter: 300, exit: 300 }}
      unmountOnExit
      mountOnEnter
    >
      <Box className={classes.successMessageContainer}>
        <Typography className={classes.message}>
          {message}
        </Typography>
      </Box>
    </Fade>
  );
};

export default SuccessMessageBox;
