import { useIntl } from 'gatsby-plugin-intl';
import { useMemo } from 'react';

const useGetNewsPageTranslation = () => {
  const { formatMessage: t, locale } = useIntl();

  return useMemo(() => {
    if (locale && t) {
      return {
        pageTitle: t({ id: 'pages.news' }),
        headerSection: {
          title: t({ id: 'news.sections.0.title' }),
          description: t({
            id: 'news.sections.0.description',
          }),
        },
        newsDetail: {
          linkText: t({ id: 'newsDetail.linkText' }),
          linkTextSuccess: t({
            id: 'newsDetail.linkTextSuccess',
          }),
        },
      };
    } else {
      return {
        pageTitle: '',
        headerSection: {
          title: '',
          description: '',
        },
        newsDetail: {
          linkText: '',
          linkTextSuccess: '',
        },
      };
    }
  }, [locale, t]);
};

export default useGetNewsPageTranslation;
