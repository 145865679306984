import { useIntl } from 'gatsby-plugin-intl';
import { useMemo } from 'react';

const useGetHomePageTranslation = () => {
  const { formatMessage: t, locale } = useIntl();
  return useMemo(() => {
    if (locale && t) {
      return {
        pageTitle: t({ id: 'pages.home' }),
        homeHeaderSection: {
          title: t({ id: 'home.sections.0.title' }),
          description: t({
            id: 'home.sections.0.description',
          }),
        },
        pageSection: {
          title: t({ id: 'home.sections.1.title' }),
          description: t({
            id: 'home.sections.1.description',
          }),
          buttonText: t({
            id: 'home.sections.1.link',
          }),
        },
        stepSection: {
          title: t({ id: 'home.sections.2.title' }),
          steps: [
            {
              title: t({
                id: 'home.sections.2.steps.0.title',
              }),
              description: t({
                id: 'home.sections.2.steps.0.description',
              }),
            },
            {
              title: t({
                id: 'home.sections.2.steps.1.title',
              }),
              description: t({
                id: 'home.sections.2.steps.1.description',
              }),
            },
            {
              title: t({
                id: 'home.sections.2.steps.2.title',
              }),
              description: t({
                id: 'home.sections.2.steps.2.description',
              }),
            },
          ],
        },
        callToActionSection: {
          title: t({ id: 'home.contactSection.title' }),
          description: t({
            id: 'home.contactSection.description',
          }),
          buttonTitle: t({
            id: 'home.contactSection.button',
          }),
        },
        startups: {
          normalLink: t({
            id: 'home.sections.3.normalLink',
          }),
          underlinedLink: t({
            id: 'home.sections.3.underlinedLink',
          }),
        },
        newsSection: {
          title: t({
            id: 'NewsSection.title',
          }),
          underlinedText: t({
            id: 'home.newsSection.underlinedLink',
          }),
          otherText: t({
            id: 'home.newsSection.normalLink',
          }),
        },
      };
    } else {
      return {
        pageTitle: '',
        homeHeaderSection: {
          title: '',
          description: '',
        },
        pageSection: {
          title: '',
          description: '',
          buttonText: '',
        },
        stepSection: {
          title: '',
          steps: [],
        },
        callToActionSection: {
          title: '',
          description: '',
          buttonTitle: '',
        },
        startups: {
          underlinedLink: '',
          normalLink: '',
        },
        newsSection: {
          title: '',
          underlinedText: '',
          otherText: '',
        },
      };
    }
  }, [locale, t]);
};

export default useGetHomePageTranslation;
