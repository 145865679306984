import React, { useEffect, useRef, useState } from 'react';
import {
  ButtonBase,
  Grid,
  Typography,
} from '@material-ui/core';
import Image from 'gatsby-image';
import Container from '../../../../components/shared/Container';
import { makeStyles } from '@material-ui/styles';
import {
  copyTextToClipboard,
  countWords,
} from '../../../../utils/functions';
import FacebookWithColor from '../../../../components/svg/FacebookWithColor';
import LinkedInWithColor from '../../../../components/svg/LinkedInWithColor';
import LinkAnchor from '../../../../components/svg/LinkAnchor';
import useGetCurrentPath from '../../../../hooks/useGetCurrentPath';
import SuccessMessageBox from '../../../../components/shared/SuccessMessageBox';
import ExternalLink from '../../../../components/shared/ExternalLink';
import styles from './NewsArticle.styles';
import useGetNewsPageTranslation from '../../../../hooks/useGetNewsPageTranslation';

const useStyles = makeStyles(styles);

const NewsArticle = ({ data }) => {
  const [copySuccess, setCopySuccess] = useState(false);
  const { newsDetail } = useGetNewsPageTranslation();
  const { currentFullPath } = useGetCurrentPath();
  const timeoutId = useRef(0);

  const {
    image: { fluid },
    category,
    title,
    text: { text },
    preamble: { preamble },
    date,
  } = data;
  const displayOneColumn = countWords(text) < 100;

  const classes = useStyles({ displayOneColumn });

  const copyUrl = async () => {
    try {
      await copyTextToClipboard(currentFullPath);
      setCopySuccess(true);
    } catch (error) {
      console.log(error);
      setCopySuccess(false);
    }
  };

  useEffect(() => {
    if (copySuccess) {
      timeoutId.current = setTimeout(() => {
        setCopySuccess(false);
        clearTimeout(timeoutId.current);
      }, 3000);
    }
    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, [copySuccess]);

  const linkedInShare = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURI(
    currentFullPath
  )}&title=${encodeURI(title)}summary=${encodeURI(
    preamble
  )}&source=LinkedIn`;

  const facebookShare = `https://www.facebook.com/sharer/sharer.php?u=${encodeURI(
    currentFullPath
  )}`;

  return (
    <Grid container className={classes.newsContainer}>
      <Container>
        <Image fluid={fluid} />
        <Grid
          item
          container
          justify="space-between"
          className={classes.newsInfo}
        >
          <Typography className={classes.category}>
            {category}
          </Typography>
          <Typography className={classes.date}>
            {date}
          </Typography>
        </Grid>
        <Typography className={classes.title}>
          {title}
        </Typography>
        <Typography className={classes.preamble}>
          {preamble}
        </Typography>
        <Typography className={classes.text}>
          {text}
        </Typography>
        <Grid
          container
          item
          className={classes.shareContainer}
        >
          <ButtonBase className={classes.shareButton}>
            <ExternalLink href={facebookShare}>
              <FacebookWithColor />
            </ExternalLink>
          </ButtonBase>
          <ButtonBase className={classes.shareButton}>
            <ExternalLink href={linkedInShare}>
              <LinkedInWithColor />
            </ExternalLink>
          </ButtonBase>
          <ButtonBase
            className={classes.shareButton}
            onClick={copyUrl}
          >
            <LinkAnchor />
            <Typography className={classes.linkText}>
              {newsDetail.linkText}
            </Typography>
            <SuccessMessageBox
              isVisible={copySuccess}
              message={newsDetail.linkTextSuccess}
            />
          </ButtonBase>
        </Grid>
      </Container>
    </Grid>
  );
};

export default NewsArticle;
