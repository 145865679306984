import React from 'react';
import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import Container from '../../Container';
import NewsCard from './NewsCard';
import { makeStyles, useTheme } from '@material-ui/styles';
import useGetCurrentPath from '../../../../hooks/useGetCurrentPath';
import Circle from '../../Circle';
import ShowMoreButton from '../../ShowMoreButton';
import QuoteCard from './QuoteCard';
import { Parallax } from 'react-scroll-parallax';
import useGetHomePageTranslation from '../../../../hooks/useGetHomePageTranslation';

const useStyles = makeStyles(
  ({
    palette: { text },
    fonts,
    spacing,
    breakpoints: { down },
  }) => ({
    newsSection: {
      padding: spacing(23, 0, 13, 0),
      color: text.primary,
      '& .parallax-inner': {
        height: '100%',
        width: '100%',
        position: 'absolute',
        top: 0,
      },
      [down('xs')]: {
        paddingTop: spacing(13),
      },
    },
    titleContainer: {
      position: 'relative',
      marginBottom: spacing(10),
      [down('xs')]: {
        marginBottom: spacing(3),
      },
    },
    title: {
      position: 'relative',
      zIndex: 3,
      [down('xs')]: {
        fontSize: fonts.size.l,
      },
    },
  })
);

const newsLink = '/news';
const quoteCategory = 'Quote';

const NewsSection = ({ data }) => {
  const theme = useTheme();
  const classes = useStyles();
  const smallScreen = useMediaQuery(
    theme.breakpoints.down('xs')
  );
  const mediumScreen = useMediaQuery(
    theme.breakpoints.down('sm')
  );

  const { newsSection } = useGetHomePageTranslation();
  const { currentPath } = useGetCurrentPath();
  return (
    <Container>
      <Grid
        container
        className={classes.newsSection}
        direction="column"
      >
        <Grid
          container
          item
          justify={
            mediumScreen && !smallScreen
              ? 'flex-start'
              : 'center'
          }
        >
          <Box className={classes.titleContainer}>
            <Typography
              variant="h2"
              className={classes.title}
            >
              {newsSection.title}
            </Typography>
            <Parallax
              y={smallScreen ? [-25, 25] : [-25, 75]}
            >
              <Circle
                size={smallScreen ? 84 : 112}
                top={{
                  xs: -25,
                  lg: -40,
                }}
                right={{
                  xs: -20,
                  lg: -30,
                }}
              />
            </Parallax>
          </Box>
        </Grid>
        <Grid container item spacing={3}>
          {data.map(
            ({
              node: {
                image: { fluid },
                text: { text },
                preamble: { preamble = {} } = {},
                category,
                ...props
              },
            }) =>
              category !== quoteCategory ? (
                <NewsCard
                  key={props.slug}
                  imgFluid={fluid}
                  category={category}
                  preamble={preamble}
                  {...props}
                />
              ) : (
                <QuoteCard
                  key={props.slug}
                  text={text}
                  cardWidth={props.cardWidth}
                />
              )
          )}
        </Grid>
        {currentPath !== newsLink && (
          <ShowMoreButton
            to={newsLink}
            underlinedText={newsSection.underlinedText}
            otherText={newsSection.otherText}
          />
        )}
      </Grid>
    </Container>
  );
};

export default NewsSection;
