import React from 'react';

const LinkAnchor = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.33301 10.8333C8.69089 11.3118 9.14747 11.7077 9.6718 11.9941C10.1961 12.2806 10.7759 12.4509 11.3719 12.4936C11.9678 12.5363 12.566 12.4503 13.1258 12.2415C13.6856 12.0327 14.1939 11.7059 14.6163 11.2833L17.1163 8.78334C17.8753 7.9975 18.2953 6.94499 18.2858 5.85251C18.2763 4.76002 17.8381 3.71497 17.0656 2.94243C16.2931 2.1699 15.248 1.7317 14.1555 1.7222C13.063 1.71271 12.0105 2.13269 11.2247 2.89168L9.79134 4.31668"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6668 9.16677C11.309 8.68833 10.8524 8.29245 10.328 8.00599C9.80372 7.71953 9.22391 7.54918 8.62796 7.50649C8.03201 7.46381 7.43385 7.5498 6.87405 7.75862C6.31425 7.96744 5.80591 8.29421 5.3835 8.71677L2.8835 11.2168C2.12451 12.0026 1.70453 13.0551 1.71402 14.1476C1.72352 15.2401 2.16172 16.2851 2.93426 17.0577C3.70679 17.8302 4.75184 18.2684 5.84433 18.2779C6.93682 18.2874 7.98933 17.8674 8.77517 17.1084L10.2002 15.6834"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LinkAnchor;
