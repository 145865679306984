export default ({
  palette: { text, secondary },
  fonts,
  spacing,
  breakpoints: { down },
}) => ({
  newsContainer: {
    padding: spacing(15, 0, 8, 0),
    color: text.primary,
    backgroundColor: secondary.main,
    '& .gatsby-image-wrapper': {
      [down('xs')]: {
        minHeight: 200,
      },
    },
    [down('md')]: {
      paddingTop: spacing(13),
    },
    [down('xs')]: {
      paddingTop: spacing(8),
    },
  },
  newsInfo: {
    padding: spacing(1, 0),
  },
  category: {
    fontSize: fonts.size.xxxs,
    fontWeight: 400,
    display: 'flex',
    '&::before': {
      content: '""',
      backgroundColor: 'currentColor',
      width: 4,
      height: 4,
      borderRadius: '50%',
      margin: 'auto',
      marginRight: 5,
    },
  },
  date: {
    fontSize: fonts.size.xxxxs,
    fontWeight: 400,
    opacity: 0.7,
  },
  title: {
    fontSize: fonts.size.xl,
    lineHeight: `${fonts.size.xl + 7}px`,
    fontWeight: 700,
    margin: spacing(0, 0, 4, 0),
    [down('xs')]: {
      fontSize: fonts.size.xxxxxm,
      lineHeight: `${fonts.size.xxxxxm + 4}px`,
    },
  },
  text: {
    fontSize: fonts.size.xs,
    lineHeight: `${fonts.size.xs + 4}px`,
    fontWeight: 400,
    whiteSpace: 'pre-line',
    columnCount: ({ displayOneColumn }) =>
      !displayOneColumn && 2,
    width: ({ displayOneColumn }) =>
      displayOneColumn && '50%',
    columnGap: spacing(7),
    [down('sm')]: {
      columnCount: '1!important',
    },
    [down('xs')]: {
      fontSize: fonts.size.xxs,
      lineHeight: `${fonts.size.xxs + 4}px`,
      width: '100%!important',
    },
  },
  preamble: {
    fontSize: fonts.size.xm,
    lineHeight: `${fonts.size.xm + 4}px`,
    fontWeight: 500,
    marginBottom: spacing(4),
    whiteSpace: 'pre-line',
    [down('xs')]: {
      fontSize: fonts.size.s,
      lineHeight: `${fonts.size.s + 4}px`,
    },
  },
  shareContainer: {
    marginTop: spacing(6),
  },
  shareButton: {
    '& a': {
      display: 'flex',
      alignItems: 'center',
    },
    '& .MuiTouchRipple-root': {
      display: 'none',
    },
    '&:nth-child(even)': {
      margin: spacing(0, 1.5),
    },
  },
  linkText: {
    fontSize: fonts.size.xxs,
    fontWeight: 700,
    textTransform: 'uppercase',
    marginLeft: spacing(1),
    color: text.primary,
  },
});
