import React from 'react';

const FacebookWithColor = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill="#425792" />
      <path
        d="M18.5037 10.4281H20.0596V7.7755C19.3062 7.69654 18.5493 7.65756 17.792 7.65872C15.5409 7.65872 14.0016 9.04341 14.0016 11.5792V13.7647H11.4609V16.7343H14.0016V24.3418H17.0471V16.7343H19.5796L19.9602 13.7647H17.0471V11.8712C17.0471 10.9953 17.2789 10.4281 18.5037 10.4281Z"
        fill="white"
      />
    </svg>
  );
};

export default FacebookWithColor;
