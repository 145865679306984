import React from 'react';

const LinkedInWithColor = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill="#0078B9" />
      <rect
        x="6.93555"
        y="7.09363"
        width="17.9704"
        height="17.9704"
        fill="white"
      />
      <path
        d="M25.727 4.41386H6.11586C5.89541 4.41079 5.67652 4.45122 5.47168 4.53283C5.26685 4.61444 5.08008 4.73563 4.92205 4.88948C4.76402 5.04333 4.63782 5.22683 4.55066 5.4295C4.4635 5.63217 4.41708 5.85004 4.41406 6.07066V25.9291C4.41708 26.1498 4.4635 26.3676 4.55066 26.5703C4.63782 26.773 4.76402 26.9565 4.92205 27.1103C5.08008 27.2642 5.26685 27.3854 5.47168 27.467C5.67652 27.5486 5.89541 27.589 6.11586 27.586H25.727C25.9475 27.589 26.1664 27.5486 26.3712 27.467C26.5761 27.3854 26.7628 27.2642 26.9208 27.1103C27.0789 26.9565 27.2051 26.773 27.2922 26.5703C27.3794 26.3676 27.4258 26.1498 27.4288 25.9291V6.07066C27.4258 5.85004 27.3794 5.63217 27.2922 5.4295C27.2051 5.22683 27.0789 5.04333 26.9208 4.88948C26.7628 4.73563 26.5761 4.61444 26.3712 4.53283C26.1664 4.45122 25.9475 4.41079 25.727 4.41386ZM11.3949 23.8089H7.92185V13.3815H11.3949V23.8089ZM9.65838 11.9216C9.1794 11.9216 8.72004 11.7312 8.38135 11.3922C8.04266 11.0533 7.85239 10.5936 7.85239 10.1142C7.85239 9.63483 8.04266 9.17511 8.38135 8.83615C8.72004 8.49719 9.1794 8.30677 9.65838 8.30677C9.91271 8.2779 10.1703 8.30313 10.4142 8.38079C10.6581 8.45845 10.8829 8.58679 11.0738 8.75742C11.2647 8.92805 11.4175 9.13712 11.5221 9.37093C11.6267 9.60474 11.6808 9.85802 11.6808 10.1142C11.6808 10.3704 11.6267 10.6236 11.5221 10.8575C11.4175 11.0913 11.2647 11.3003 11.0738 11.471C10.8829 11.6416 10.6581 11.7699 10.4142 11.8476C10.1703 11.9253 9.91271 11.9505 9.65838 11.9216ZM23.9211 23.8089H20.448V18.2128C20.448 16.8109 19.9502 15.8956 18.6883 15.8956C18.2978 15.8985 17.9175 16.0211 17.5987 16.2469C17.28 16.4727 17.038 16.7909 16.9055 17.1585C16.8149 17.4308 16.7756 17.7176 16.7897 18.0043V23.7973H13.3167C13.3167 23.7973 13.3167 14.3199 13.3167 13.3699H16.7897V14.8413C17.1052 14.2934 17.5641 13.842 18.1169 13.5358C18.6698 13.2295 19.2956 13.08 19.927 13.1034C22.2424 13.1034 23.9211 14.598 23.9211 17.8073V23.8089Z"
        fill="#0078B9"
      />
    </svg>
  );
};

export default LinkedInWithColor;
