import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { string } from 'prop-types';
import LetterLogo from '../../../svg/LetterLogo';
import styles from './QuoteCard.styles';

const useStyles = makeStyles(styles);

const QuoteCard = ({ text, cardWidth }) => {
  const smallCard = cardWidth === '1/3';
  const classes = useStyles({
    smallCard,
  });
  const columnWidth = smallCard
    ? 4
    : cardWidth === '2/3'
    ? 8
    : 12;

  return (
    <Grid
      item
      className={classes.quoteCard}
      xs={12}
      sm={6}
      md={columnWidth}
    >
      <Box className={classes.quoteCardInner}>
        <Typography
          className={`${classes.quote} ${
            smallCard
              ? classes.quoteSmall
              : classes.quoteLarge
          }`}
        >
          {text}
        </Typography>
        <LetterLogo className={classes.logoIcon} />
        <Box
          className={`${classes.patternBase} ${
            smallCard
              ? classes.patternSmall
              : classes.patternLarge
          }`}
        />
      </Box>
    </Grid>
  );
};

QuoteCard.propTypes = {
  text: string,
  cardWidth: string,
};

export default QuoteCard;
