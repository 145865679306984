import React from 'react';
import { graphql } from 'gatsby';
import NewsSection from '../components/shared/sections/news-section';
import CallToActionSection from '../components/shared/sections/call-to-action-section';
import Seo from '../components/config/Seo';
import NewsArticle from '../components/sections/news/article/NewsArticle';
import useGetHomePageTranslation from '../hooks/useGetHomePageTranslation';

const News = ({ data }) => {
  const {
    callToActionSection,
  } = useGetHomePageTranslation();
  return (
    <>
      <Seo
        title={
          data.allContentfulNewsEntry.edges[0].node.title
        }
      />
      <NewsArticle
        data={data.allContentfulNewsEntry.edges[0].node}
      />
      <NewsSection data={data.newsFeed.edges} />
      <CallToActionSection
        title={callToActionSection.title}
        description={callToActionSection.description}
        buttonTitle={callToActionSection.buttonTitle}
      />
    </>
  );
};

export default News;

export const query = graphql`
  query NewsDetailPage($slug: String!) {
    allContentfulNewsEntry(
      filter: { slug: { eq: $slug } }
    ) {
      edges {
        node {
          image {
            fluid(
              quality: 85
              maxWidth: 1921
              maxHeight: 1081
              resizingBehavior: PAD
              background: "transparent"
            ) {
              ...GatsbyContentfulFluid_noBase64
            }
          }
          category
          title
          text {
            text
          }
          preamble {
            preamble
          }
          date(formatString: "dddd, MMM Do - YYYY")
        }
      }
    }
    newsFeed: allContentfulNewsEntry(
      limit: 3
      sort: { fields: date, order: DESC }
      filter: { category: { ne: "Quote" } }
    ) {
      edges {
        node {
          image {
            fluid(quality: 85) {
              ...GatsbyContentfulFluid_noBase64
            }
          }
          category
          title
          text {
            text
          }
          date(formatString: "dddd, MMM Do - YYYY")
          slug
          cardWidth
          preamble {
            preamble
          }
        }
      }
    }
  }
`;
