import {
  ButtonBase,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { Link } from 'gatsby-plugin-intl';
import { makeStyles, useTheme } from '@material-ui/styles';
import { string } from 'prop-types';
import Arrow from '../../components/svg/Arrow';
import React from 'react';

const useStyles = makeStyles(
  ({
    palette: { primary, text },
    fonts,
    spacing,
    breakpoints: { down, only },
  }) => ({
    showMoreButton: {
      padding: spacing(1),
      marginTop: spacing(16),
      display: 'flex',
      transition: 'opacity .3s',
      '& .MuiTouchRipple-root': {
        display: 'none',
      },
      '&:hover': {
        opacity: 0.8,
        '& svg': {
          animation: '$hoverEffect 1.5s infinite',
        },
      },
      [down('sm')]: {
        marginTop: spacing(8),
      },
      [only('sm')]: {
        justifyContent: 'flex-start',
      },
      [down('xs')]: {
        marginTop: spacing(6),
      },
    },
    showMoreLink: {
      textDecoration: 'none',
      color: text.primary,
      display: 'flex',
    },
    arrow: {
      margin: spacing(1, 0, 0, 3),
      [down('xs')]: {
        margin: spacing(0, 0, 0, 1),
      },
    },
    linkBase: {
      fontSize: fonts.size.l,
      fontWeight: 700,
      lineHeight: `${fonts.size.l}px`,
      [down('xs')]: {
        fontSize: fonts.size.xm,
        lineHeight: `${fonts.size.xm}px`,
      },
    },
    underlinedText: {
      position: 'relative',
      '&::after': {
        content: '""',
        position: 'absolute',
        bottom: 2,
        left: 0,
        height: 7,
        width: '100%',
        background: primary.dark,
        opacity: 0.2,
        zIndex: -1,
        [down('xs')]: {
          opacity: 0.1,
          bottom: 0,
        },
      },
    },
    otherText: {
      whiteSpace: 'pre',
    },
    '@keyframes hoverEffect': {
      '0%': {
        transform: 'translateX(0)',
      },
      '50%': {
        transform: 'translateX(10px)',
      },
      '100%': {
        transform: 'translateX(0)',
      },
    },
  })
);

const ShowMoreButton = ({
  to,
  underlinedText,
  otherText,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const smallScreen = useMediaQuery(
    theme.breakpoints.down('xs')
  );
  return (
    <ButtonBase className={classes.showMoreButton}>
      <Link to={to} className={classes.showMoreLink}>
        <Typography
          className={`${classes.linkBase} ${classes.underlinedText}`}
        >
          {underlinedText}
          {smallScreen && otherText}
        </Typography>
        {!smallScreen && (
          <Typography
            className={`${classes.linkBase} ${classes.otherText}`}
          >
            {otherText}
          </Typography>
        )}
      </Link>
      <Arrow
        tailed={!smallScreen}
        className={classes.arrow}
      />
    </ButtonBase>
  );
};

export default ShowMoreButton;

ShowMoreButton.propTypes = {
  to: string,
  underlinedText: string,
  otherText: string,
};
