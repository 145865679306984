import {
  ButtonBase,
  Grid,
  Typography,
} from '@material-ui/core';
import { Link } from 'gatsby-plugin-intl';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import Img from 'gatsby-image';
import styles from './NewsCard.styles';
import { shape, string } from 'prop-types';
import useGetCurrentPath from '../../../../hooks/useGetCurrentPath';

const useStyles = makeStyles(styles);

const NewsCard = ({
  title,
  category,
  imgFluid,
  date,
  cardWidth,
  slug,
  preamble,
}) => {
  const smallCard = cardWidth === '1/3';
  const { currentPath } = useGetCurrentPath();
  const displayOnlyTop = currentPath === '/';
  const classes = useStyles({
    smallCard,
    displayOnlyTop,
  });
  let columnWidth = smallCard
    ? 4
    : cardWidth === '2/3'
    ? 8
    : 12;
  columnWidth = currentPath.includes('/news/')
    ? 4
    : columnWidth;

  return (
    <Grid
      item
      className={classes.newsCard}
      xs={12}
      sm={6}
      md={columnWidth}
    >
      <ButtonBase>
        <Link to={`/news/${slug}`} className={classes.link}>
          <Img fluid={imgFluid} className={classes.img} />
          <Grid
            container
            justify="space-between"
            className={classes.header}
          >
            <Typography
              variant="body2"
              component="p"
              className={classes.category}
            >
              {category}
            </Typography>
            <Typography
              component="p"
              className={classes.date}
            >
              {date}
            </Typography>
          </Grid>
          <Typography
            variant="h6"
            component="h3"
            className={classes.title}
          >
            {title}
          </Typography>
          <Typography
            className={classes.text}
            variant="subtitle2"
            component="p"
          >
            {preamble}
          </Typography>
        </Link>
      </ButtonBase>
    </Grid>
  );
};

NewsCard.propTypes = {
  title: string,
  category: string,
  imgFluid: shape({}),
  date: string,
  cardWidth: string,
  slug: string,
  preamble: string,
};

export default NewsCard;
