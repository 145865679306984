import patterns from '../../../../constants/patterns';

export default ({
  palette: { primary, text },
  fonts,
  spacing,
  breakpoints: { down, up },
}) => ({
  quoteCard: {
    width: '100%',
    height: 545,
    position: 'relative',
    [down('xs')]: {
      height: 436,
    },
  },
  quoteCardInner: {
    backgroundColor: ({ smallCard }) =>
      smallCard ? primary.main : primary.dark,
    color: ({ smallCard }) =>
      smallCard ? text.primary : text.secondary,
    height: '100%',
    position: 'relative',
  },
  quote: {
    fontWeight: 700,
    padding: spacing(5),
    whiteSpace: 'pre-line',
    position: 'relative',
    zIndex: 4,
  },
  quoteSmall: {
    fontSize: fonts.size.xxl,
    lineHeight: `${fonts.size.xxl + 4}px`,
    maxWidth: '90%',
    [down('xs')]: {
      fontSize: fonts.size.xxxm,
      lineHeight: `${fonts.size.xxxm + 4}px`,
    },
  },
  quoteLarge: {
    fontSize: fonts.size.xxxl,
    lineHeight: `${fonts.size.xxxl + 4}px`,
    maxWidth: '60%',
    [down('sm')]: {
      maxWidth: '90%',
      fontSize: fonts.size.xxl,
      lineHeight: `${fonts.size.xxl + 4}px`,
    },
    [down('xs')]: {
      fontSize: fonts.size.xxxm,
      lineHeight: `${fonts.size.xxxm + 4}px`,
    },
  },
  logoIcon: {
    position: 'absolute',
    right: 40,
    bottom: ({ smallCard }) => smallCard && 40,
    zIndex: 4,
    [up('md')]: {
      top: ({ smallCard }) => !smallCard && 40,
    },
    [down('sm')]: {
      bottom: 40,
    },
  },
  patternBase: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    height: '100%',
    width: '100%',
    opacity: 0.6,
    overflow: 'hidden',
    content: '""',
    '&::before': {
      position: 'absolute',
      minHeight: '100%',
      maxHeight: '100%',
      bottom: 0,
      content: '""',
    },
    '&::after': {
      position: 'absolute',
      bottom: 0,
      content: '""',
      overflow: 'hidden',
      height: '100%',
    },
  },
  patternLarge: {
    '&::before': {
      right: 0,
      transform: 'rotate(-90deg)',
      width: 529,
      backgroundImage: `url(${patterns['archLight']})`,
      backgroundPositionY: 10,
      [down('xs')]: {
        width: 436,
        backgroundPositionY: 'initial',
        backgroundPositionX: 10,
      },
    },
    '&::after': {
      right: 0,
      width: '60%',
      background:
        'radial-gradient(69.23% 76.93% at 92.15% -3.59%, rgba(29, 28, 34, 0.04) 0%, #1D1C22 100%)',
      transform: 'matrix(1, 0, 0, -1, 0, 0)',
      [down('sm')]: {
        width: '100%',
      },
    },
  },
  patternSmall: {
    '&::before': {
      left: 0,
      width: '100%',
      backgroundImage: `url(${patterns['arrow']})`,
      opacity: 0.2,
      backgroundPositionY: 15,
    },
    '&::after': {
      left: 0,
      width: 725,
      background:
        'radial-gradient(43.58% 60.41% at 88.75% 18.11%, rgba(230, 252, 87, 0.04) 0%, #E6FC57 100%)',
      transform: 'rotate(-180deg)',
    },
  },
});
